import React, { useEffect, useMemo, useState } from "react";
import { css } from "@emotion/css";
import { history } from "../history";
import { format } from "date-fns";
import posthog from "../lib/analytics/posthog";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { toast } from "react-toastify";
import ButtonUI from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import { Collapse, TextField } from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import { VariableSizeList } from "react-window";
import PropTypes from "prop-types";
import ListSubheader from "@material-ui/core/ListSubheader";
import EmptyFolder from "./empty-folder.png";
import { useFeatureFlagPayload } from "posthog-js/react";

import { FolderOptions } from "./FolderOptions";
import { Header } from "./Header";

import { CarrierMarketingCourses } from "./CarrierMarketingCourses";
import CircularProgress from "@material-ui/core/CircularProgress";

import FolderMove from "./FolderMove.png";

import SimpleReactFileUpload from "../Upload/Upload";

import Tooltip from "@material-ui/core/Tooltip";

import { CustomAxios } from "../redux/axios/axios";
import CoursesEmptyImage from "./courses-empty.png";
import NoHeaderImage from "./no-header-image.png";
import { useQueryAPI, useRequests, useResource } from "../react-query";
import {
  Trash,
  ChartBar,
  DownloadSimple,
  CopySimple,
  Eye,
  ArrowUUpLeft,
  Archive,
  FolderSimple,
  ArrowRight,
} from "phosphor-react";
import { useStore } from "../store-provider/use-store";
import { ACLWrapper } from "../shared/components/acl-wrapper";
import { useAcls } from "../shared/use-acls";
import { Autocomplete } from "@material-ui/lab";
import { Box, Text, Button, Tabs, Modal } from "../shared/components";

import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { useBusinessAuth } from "../Dashboard/use-business-auth";

// include the widget CSS file whichever way your bundler supports it
import "react-csv-importer/dist/index.css";

import { animation, container, flex } from "../shared/shared-styles";
import { groupBy } from "lodash";
import { colors } from "../shared/styles";
import { guides } from "../react-query/resources/guides";
import { useModal } from "../common/hooks/useModal";
import { useModalWithData } from "../common/hooks/useModalWithData";
import { AssignGuideModal } from "./AssignGuideModal";
import { NewFolderModal } from "./NewFolderModal";
import { RenameFolderModal } from "./RenameFolderModal";
import { MoveGuideModal } from "./MoveGuideModal";
import { RemoveFolderModal } from "./RemoveFolderModal";
import { DeleteGuideModal } from "./DeleteGuideModal";
import { DuplicateGuideModal } from "./DuplicateGuideModal";
import { Image } from "phosphor-react";
import { BriteLoader } from "../shared/components/brite-loader";
import { copyCourseFn } from "../redux/replace-redux-actions";

const courseListContainer = css`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 24px;
  margin-left: -10px;
  ${animation("fadeIn", ".2s ease")}
  width: 100%;
`;

const courseCard = css`
  width: 100%;
  max-width: 310px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  margin: 16px;
  .imageCard {
    margin: 8px auto;
    display: flex;
    justify-content: center;
    padding: 24px;
    height: 208px;
    align-items: center;
  }
  .hoverCard {
    height: 208px;
    ${animation("fadeIn", ".2s ease")}
  }

  :hover {
    .imageCard {
      display: none;
    }
  }
  :not(:hover) {
    .hoverCard {
      display: none;
    }
  }
`;

const COURSE_TAB = "courses";

const NPS_PROMPT_TYPE = "nps";
const ARCHIVED_TAB = "archived";
const PUBLISHED_TAB = "published";

//HideableCheckbox takes a param to control whether or not the checkbox is hidden
const HideableCheckbox = (hideIfUnchecked, checked) => {
  const icon = <CheckBoxOutlineBlankIcon />;
  const checkedIcon = <CheckBoxIcon />;
  const StyledBox = withStyles({
    root: {
      opacity: hideIfUnchecked ? "0" : "1", // invisible when not checked and hideUnchecked is true
      "&.Mui-checked": {
        color: "#25282D",
        opacity: "1",
      },
    },
  })(Checkbox);

  return <StyledBox icon={icon} checkedIcon={checkedIcon} checked={checked} />;
};

// BriteFormControlLabel provides a custom dropdown multi-select line item with brite colors and styling.
// If a "Select All" label is provided, it will receive special treatment.
const BriteFormControlLabel = (props) => {
  const { label, selected } = props;
  const isSelectAll = label === "Select All";
  const StyledFormControlLabel = withStyles({
    root: {
      borderBottom: isSelectAll ? "1px solid #D1DAE3" : "none",
      "& .MuiFormControlLabel-label": {
        width: "100%",
        fontSize: "18px",
        fontWeight: isSelectAll ? "bold" : "normal",
      },
      width: "100%",
      justifyContent: "space-between",
    },
  })(FormControlLabel);
  // TODO: this key doesn't seem to satisfy react's unique key requirement :shrug:
  return (
    <div
      key={label}
      className={css`
        width: 100%;
        justify-content: space-between;
      `}
      onClick={(e) => {
        e.preventDefault();
      }}
    >
      <StyledFormControlLabel
        value="start"
        control={HideableCheckbox(!isSelectAll, selected)} // Don't hide the checkbox for "select all"
        labelPlacement="start"
        label={label}
        onClick={(e) => e.preventDefault()}
      />
    </div>
  );
};

const BriteChip = (props) => {
  const { invisible, label } = props;
  const BChip = withStyles({
    root: {
      opacity: invisible ? "0" : "1",
      backgroundColor: "#E8EDF3",
      "& .MuiChip-label": {
        color: "#25282D",
      },
      "& .MuiChip-deleteIcon": {
        color: "#25282D",
      },
    },
  })(Chip);
  return <BChip label={label} />;
};

const listBoxStyled = makeStyles({
  listbox: {
    boxSizing: "border-box",
    "& ul": {
      padding: 0,
      margin: 0,
    },
  },
});

const OuterElementContext = React.createContext({});
const OuterElementType = React.forwardRef((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data) {
  const ref = React.useRef(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

const LISTBOX_PADDING = 0; // px
const LISTBOX_LENGTH = 5; // number of entries to show in the listbox

function renderRow(props) {
  const { data, index, style } = props;
  return React.cloneElement(data[index], {
    style: {
      ...style,
      top: style.top + LISTBOX_PADDING,
    },
  });
}

// Adapter for react-window
const ListboxComponent = React.forwardRef(function ListboxComponent(
  props,
  ref
) {
  const { children, ...other } = props;
  const itemData = React.Children.toArray(children);
  const itemCount = itemData.length;
  const itemSize = 48;

  const getChildSize = (child) => {
    if (React.isValidElement(child) && child.type === ListSubheader) {
      return 48;
    }

    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return LISTBOX_LENGTH * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

ListboxComponent.propTypes = {
  children: PropTypes.node,
};

const StyledAutocomplete = withStyles({
  "@global": {
    '.MuiAutocomplete-option[aria-selected="true"]': {
      background: "#E8EDF3",
    },
    '.MuiAutocomplete-option[data-focus="true"]': {
      background: "#D1DAE3",
    },
  },
})(Autocomplete);

export const BriteSearchableMultiSelect = (props) => {
  const {
    label,
    value,
    options,
    defaultValues,
    allAreSelected,
    onChange,
    labelKey,
    closeOnSelect,
    disabled,
  } = props;

  const valueArray = value !== undefined ? Array.from(value) : [];
  const defaultArray =
    defaultValues !== undefined ? Array.from(defaultValues) : [];
  const optionsArray = options !== undefined ? Array.from(options) : [];

  const numberOfLabelsToShow = 4;
  return (
    <div>
      <div
        className={css`
          margin-bottom: 16px;
        `}
      >
        {valueArray.map(
          (v, index) =>
            index < numberOfLabelsToShow && (
              <div
                key={v[labelKey]}
                className={css`
                  display: inline-block;
                  margin-right: 2px;
                `}
              >
                <BriteChip label={v ? v[labelKey] : ""} />
              </div>
            )
        )}
        <b>
          {valueArray.length > numberOfLabelsToShow &&
            " +" + String(valueArray.length - numberOfLabelsToShow)}
        </b>
        {/* Leave an invisible chip here so the size is consistent even when no chips are present */}
        {valueArray.length === 0 && <BriteChip invisible={true} />}
      </div>
      <StyledAutocomplete
        multiple
        disableCloseOnSelect={!closeOnSelect}
        options={optionsArray}
        default={defaultArray}
        value={valueArray}
        getOptionLabel={(o) => (o ? o[labelKey] : "")}
        ListboxComponent={ListboxComponent} // This gives us "virtualization" for a much faster loading component. It was painfully slow with 800 slack channels.
        classes={listBoxStyled()}
        renderTags={() => null} // Since we show the tags above the input, don't show them in the input (makes search confusing inside box)
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label={label}
            placeholder="Search"
            key={label}
          />
        )}
        renderOption={(option, { selected }) =>
          option ? (
            <BriteFormControlLabel
              label={option[labelKey]}
              selected={option.id === "all" ? allAreSelected : selected}
            />
          ) : null
        }
        onChange={onChange}
        disabled={disabled}
      />
    </div>
  );
};

const transformFolders = (data, courses) => {
  let tempFolders = data || [];

  for (let tempFolder of tempFolders) {
    tempFolder.count = 0;
  }

  let grouped = groupBy(courses, function (course) {
    return course.CourseFolderID;
  });

  const keys = Object.keys(grouped);
  for (const key of keys) {
    for (let tempFolder of tempFolders) {
      if (tempFolder.ID === key) {
        for (let guide of grouped[key]) {
          if (!guide.ArchivedAt && !guide.IsPublished) {
            tempFolder.count += 1;
          }
        }
      }
    }
  }
  return tempFolders;
};

export const CoursesContainer = (props) => {
  const mergeTagFF = useFeatureFlagPayload("merge-tags");
  const {
    data: { isBriteUser, user, selectedBusiness, companies },
    setStore,
  } = useStore();

  const newFolderModal = useModal();
  const {
    isOpen: assignGuideIsOpen,
    data: assignGuide,
    show: showAssignGuide,
    hide: hideAssignGuide,
  } = useModalWithData();
  const {
    isOpen: renameFolderIsOpen,
    data: renameFolder,
    show: showRenameFolder,
    hide: hideRenameFolder,
  } = useModalWithData();
  const {
    isOpen: moveGuideIsOpen,
    data: moveGuide,
    show: showMoveGuide,
    hide: hideMoveGuide,
  } = useModalWithData();
  const {
    isOpen: removeFolderIsOpen,
    data: removeFolder,
    show: showRemoveFolder,
    hide: hideRemoveFolder,
  } = useModalWithData();
  const {
    isOpen: deleteGuideIsOpen,
    data: deleteGuideId,
    show: showDeleteGuide,
    hide: hideDeleteGuide,
  } = useModalWithData();
  const {
    isOpen: duplicateGuideIsOpen,
    data: duplicateGuide,
    show: showDuplicateGuide,
    hide: hideDuplicateGuide,
  } = useModalWithData();
  const {
    isOpen: uploadImageIsOpen,
    data: uploadImage,
    show: showUploadImage,
    hide: hideUploadImage,
  } = useModalWithData();

  const { refreshAcls } = useBusinessAuth();

  const canWrite = useAcls(["write:course"]);

  const { ID: userId = "" } = user;
  const { ID: businessId = "", Type = "single" } = selectedBusiness;

  const routeToBuilder = async (id) => {
    await refreshAcls(id);
    history.push(`/${businessId}/courses/${id}`);
  };

  const [tab, setTab] = useState(COURSE_TAB);

  const [showDownloadPDFModal, setShowDownloadPDFModal] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const guidesResource = useResource(guides);
  const guidesRequests = useRequests(guidesResource);

  const { data: courses, isLoading } = guidesResource?.query;

  const {
    data: folders,
    refetch: fetchFolders,
    isLoading: isLoadingFolder,
  } = useQueryAPI({
    url: `/v1/course/folder`,
    select: (data) => transformFolders(data, courses),
    enabled: !!isLoading,
    defaultValue: [],
  });

  const [currentFolder, setCurrentFolder] = React.useState(null);

  useEffect(() => {
    if (currentFolder) {
      setCurrentFolder(null);
    }
  }, [businessId]);

  const isGuideAvailableOutsideOfFolders = () => {
    for (let course of courses) {
      if (!course.CourseFolderID && !course.ArchivedAt && !course.IsPublished) {
        return true;
      }
    }

    return false;
  };

  const isFolderEmpty = () => {
    let isEmpty = true;

    let grouped = groupBy(courses, function (course) {
      return course.CourseFolderID;
    });

    const keys = Object.keys(grouped);
    for (const key of keys) {
      if (currentFolder.ID === key) {
        if (
          grouped[key].some(
            ({ IsPublished, ArchivedAt }) => !IsPublished && !ArchivedAt
          )
        ) {
          isEmpty = false;
        }
      }
    }

    return isEmpty;
  };

  React.useEffect(() => {
    if (courses.length > 0) {
      fetchFolders();
    }
  }, [courses.length]);

  const fetchRecentPrompt = async () => {
    // Default to true in case of network error, or issue with the API.
    let hasBeenSurveyedRecently = true;
    try {
      // Fetch time since last prompt from api.
      const user_prompts = await CustomAxios.get(
        `public/v1/bp/user_prompt?id=eq.${userId}&prompt_type=eq.${NPS_PROMPT_TYPE}`,
        {
          headers: {
            "Accept-Profile": "brite_public",
          },
        }
      );
      // Because we filter by prompt_type in the request, we should only get one result.
      if (user_prompts.data.length > 0) {
        // If prompt type is found, check if it has been long enough since last prompt.
        const lastPrompt = new Date(user_prompts.data[0].prompted_at);

        const now = new Date();
        const timeSinceLastPrompt = now - lastPrompt;
        const daysSinceLastPrompt = timeSinceLastPrompt / (1000 * 60 * 60 * 24);
        if (daysSinceLastPrompt > 90) {
          // Set hasBeenSurveyedRecently to false if it has been 90 days or more since last prompt.
          hasBeenSurveyedRecently = false;
        }
      } else {
        // If prompt type is not found, and there are not errors from the api, the user has not been prompted for this survey type before.
        hasBeenSurveyedRecently = false;
      }
    } catch (err) {
      console.warn(err);
    }
    setStore("canSurveyUser", !hasBeenSurveyedRecently);
  };

  useEffect(() => {
    fetchRecentPrompt();
  }, [userId]);

  const handleDuplicate = (course) => {
    if (isBriteUser || companies.length) {
      showDuplicateGuide(course);
    } else {
      copyCourseFn(course?.ID);
    }
  };

  useEffect(() => {
    return () => {
      document.body.style.cssText = "";
    };
  }, []);

  const downloadAsPDF = async (courseID) => {
    setShowDownloadPDFModal(true);
    setLoading(true);
    setError("");

    let isBriteEditor = false;

    try {
      let response = await CustomAxios.get(`/v2/course/${courseID}/pages`);

      let pages = response.data || [];

      for (let page of pages) {
        if (page?.Type === "brite-editor") {
          isBriteEditor = true;
          break;
        }
      }

      setLoading(false);
    } catch (e) {
      setLoading(false);
      setError("Error downloading pdf. Please try again.");
      isBriteEditor = false;
    }

    if (isBriteEditor) {
      console.log("error");
      setError("Download as pdf is a work in progress. Check back soon");
    }

    if (!isBriteEditor) {
      try {
        await CustomAxios.post(`/v1/course/${courseID}/download/${userId}`);
        setLoading(false);
        posthog.capture("Downloaded PDF", {
          courseId: courseID,
        });
      } catch (e) {
        setLoading(false);
        setError("Error downloading pdf. Please try again.");
      }
    }
  };

  const archiveCourse = async (course) => {
    try {
      const guidesPut = guides.utils.getPut(course?.ID, {
        ...course,
        ArchivedAt: new Date(),
      });
      await guidesRequests.put(guidesPut);
    } catch (err) {
      toast.error(`Error archiving guide.`);
    }
  };

  const unArchiveCourse = async (course) => {
    try {
      setTab(COURSE_TAB);
      const guidesPut = guides.utils.getPut(course.ID, {
        ...course,
        ArchivedAt: null,
      });
      await guidesRequests.put(guidesPut);
    } catch (err) {
      toast.error(`Error unArchiving guide.`);
    }
  };

  const isCarrier = Type === "carrier";
  const checkIsCourseMade = courses.some(
    ({ IsPublished, ArchivedAt }) => (!IsPublished || !isCarrier) && !ArchivedAt
  );
  const checkIsCourseArchivedMade = courses.some(
    ({ ArchivedAt }) => ArchivedAt
  );
  const checkIsCoursePublishedMade = courses.some(
    ({ IsPublished }) => IsPublished
  );
  const isManagingMultipleBusinesses = Type === "multi" || Type === "carrier";

  const tabs = useMemo(() => {
    let tabs = { [COURSE_TAB]: "All Guides" };
    if (Type === "multi" && mergeTagFF?.value) {
      tabs[PUBLISHED_TAB] = "Base Guides";
    }
    tabs[ARCHIVED_TAB] = "Archived";
    return tabs;
  }, [Type, mergeTagFF?.value]);

  const showFolders =
    !isLoading &&
    !isLoadingFolder &&
    folders &&
    folders?.length > 0 &&
    !currentFolder;

  return (
    <Box
      page
      className={css`
        overflow: auto;
        padding-bottom: 24px;
      `}
    >
      {isLoading ? <BriteLoader /> : null}
      <Header
        currentFolder={currentFolder}
        setCurrentFolder={setCurrentFolder}
        toggleNewFolder={newFolderModal.toggle}
        routeToBuilder={routeToBuilder}
      />
      {!currentFolder && (
        <Tabs tabs={tabs} current={tab} setCurrent={(tab) => setTab(tab)} />
      )}
      {tab === COURSE_TAB && (
        <>
          <Collapse in={showFolders}>
            <div
              className={css`
                margin-top: 40px;
              `}
            >
              <div
                className={css`
                  color: #25282d;

                  font-family: Roboto;
                  font-size: 24px;
                  font-style: normal;
                  font-weight: 700;
                  margin-left: 16px;
                `}
              >
                Folders
              </div>
              <div
                className={css`
                  display: flex;
                  flex-wrap: wrap;
                  align-items: center;
                  gap: 40px;
                  margin-top: 16px;
                  margin-left: 9px;
                `}
              >
                {folders.map((folder) => {
                  return (
                    <div
                      className={css`
                        ${container?.box}
                        ${container?.hover}
                          padding: 24px;
                        width: 320px;
                        .hover-arrow {
                          ${animation("fadeIn", ".3s ease")}
                        }
                        :not(:hover) {
                          .hover-arrow {
                            display: none;
                          }
                        }
                      `}
                      onClick={() => setCurrentFolder(folder)}
                    >
                      <div
                        className={css`
                          display: flex;
                          justify-content: space-between;
                          align-items: center;
                          padding-bottom: 8px;
                        `}
                      >
                        <FolderSimple />
                        <FolderOptions
                          rename={() => {
                            showRenameFolder(folder);
                          }}
                          remove={() => {
                            showRemoveFolder(folder);
                          }}
                        />
                      </div>
                      <Text h4>{folder.Name}</Text>
                      <div
                        className={css`
                          ${flex("space-between")}
                          padding-top: 8px;
                        `}
                      >
                        <Text>
                          {folder.count}{" "}
                          {`Guide${folder.count !== 1 ? "s" : ""}`}
                        </Text>
                        <ArrowRight
                          size={16}
                          color={colors.gray[500]}
                          className="hover-arrow"
                        />
                      </div>
                    </div>
                  );
                })}
              </div>

              {!isLoading && isGuideAvailableOutsideOfFolders() && (
                <div
                  className={css`
                    margin-left: 16px;
                    color: #25282d;
                    margin-top: 40px;
                    font-family: Roboto;
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 700;
                    margin-bottom: -24px;
                  `}
                >
                  Guides
                </div>
              )}
            </div>
          </Collapse>
          <Box flex="left">
            {!isLoading && checkIsCourseMade ? (
              <div className={courseListContainer}>
                {courses.map((course) => {
                  if (course.ArchivedAt) {
                    return null;
                  }
                  if (!currentFolder && course.CourseFolderID) {
                    return null;
                  }

                  if (
                    currentFolder &&
                    course.CourseFolderID !== currentFolder.ID
                  ) {
                    return null;
                  }
                  return (
                    <div key={course.ID} className={courseCard}>
                      <div className="imageCard">
                        {!course.LogoURL ? (
                          <img
                            alt="Guide Logo"
                            className={css`
                              width: 300px;
                              height: 160px;
                            `}
                            src={NoHeaderImage}
                          />
                        ) : (
                          <img
                            className={css`
                              max-height: 80px;
                              max-width: 250px;
                            `}
                            src={course.LogoURL}
                            alt="Guide Logo"
                          />
                        )}
                      </div>

                      <div className="hoverCard">
                        <div
                          className={css`
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            margin-top: 16px;
                          `}
                        >
                          <ACLWrapper
                            acls={["write:course"]}
                            resourceId={course.ID}
                          >
                            <Tooltip title="Archive" placement="top">
                              <IconButton
                                aria-label="Archive"
                                onClick={() => archiveCourse(course)}
                              >
                                <Archive />
                              </IconButton>
                            </Tooltip>
                          </ACLWrapper>

                          <Tooltip title="Move to a Folder" placement="top">
                            <IconButton
                              aria-label="Move Folder"
                              onClick={() => {
                                if (folders.length > 0) {
                                  showMoveGuide(course);
                                } else {
                                  newFolderModal.toggle();
                                }
                              }}
                            >
                              <img
                                src={FolderMove}
                                width="32"
                                height="32"
                                alt="Move to a Folder"
                              />
                            </IconButton>
                          </Tooltip>

                          <ACLWrapper
                            acls={["write:course"]}
                            resourceId={course.ID}
                          >
                            <Tooltip title="Duplicate" placement="top">
                              <IconButton
                                aria-label="Duplicate"
                                onClick={() => handleDuplicate(course)}
                              >
                                <CopySimple />
                              </IconButton>
                            </Tooltip>
                          </ACLWrapper>
                          <ACLWrapper
                            acls={["write:course"]}
                            resourceId={course.ID}
                          >
                            <Tooltip
                              title="Upload a guide header image"
                              placement="top"
                            >
                              <IconButton
                                aria-label="Upload a guide header image"
                                onClick={() => {
                                  showUploadImage(course);
                                }}
                              >
                                <Image />
                              </IconButton>
                            </Tooltip>
                          </ACLWrapper>
                          {!isManagingMultipleBusinesses && (
                            <Tooltip title="View" placement="top">
                              <IconButton
                                aria-label="View"
                                onClick={() => {
                                  if (course.VanityURL) {
                                    window.open(
                                      `https://britehr.app/${course.VanityURL}?lt=preview`
                                    );
                                  } else {
                                    window.open(
                                      `https://britehr.app/${course.ID}?lt=preview`
                                    );
                                  }
                                }}
                              >
                                <Eye />
                              </IconButton>
                            </Tooltip>
                          )}
                        </div>
                        <Box
                          css={`
                            padding-top: 16px;
                            height: 120px;
                            width: 315px;
                          `}
                          flex="center space-between column"
                        >
                          <Button
                            css={`
                              width: 180px;
                            `}
                            onClick={() => routeToBuilder(course.ID)}
                          >
                            <ACLWrapper
                              acls={["write:course"]}
                              resourceId={course.ID}
                              alternateChildren={"View Guide"}
                            >
                              Edit Guide
                            </ACLWrapper>
                          </Button>

                          {canWrite ? (
                            isManagingMultipleBusinesses ? (
                              <Button
                                css={`
                                  width: 180px;
                                `}
                                secondary
                                onClick={() => showAssignGuide(course)}
                              >
                                Assign Guide
                              </Button>
                            ) : (
                              <Button
                                css={`
                                  width: 180px;
                                `}
                                onClick={() => {
                                  history.push(
                                    `/${businessId}/messaging?courseId=${course.ID}`
                                  );
                                }}
                                secondary
                              >
                                Send Guide
                              </Button>
                            )
                          ) : null}
                        </Box>
                      </div>
                      <div
                        className={css`
                          border-top: 1px solid #d1dae3;
                        `}
                      >
                        <div
                          className={css`
                            padding: 16px 24px;
                          `}
                        >
                          <Text
                            label
                            css={`
                              font-size: 22px;
                            `}
                          >
                            {course.Name}
                          </Text>

                          <Text
                            css={`
                              font-size: 18px;
                              padding-top: 8px;
                            `}
                          >
                            {format(new Date(course.CreatedAt), "MMMM yyyy")}
                          </Text>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : null}

            {!isLoading &&
              !checkIsCourseMade &&
              !currentFolder &&
              folders.length === 0 && (
                <div
                  className={css`
                    margin: 50px auto;
                  `}
                >
                  <div
                    className={css`
                      margin-bottom: 77px;
                      display: flex;
                      justify-content: center;
                    `}
                  >
                    <img src={CoursesEmptyImage} alt="Guide" />
                  </div>
                  <div
                    className={css`
                      font-size: 22px;
                      text-align: center;
                      font-weight: bold;
                      line-height: 29px;
                      color: #25282d;
                    `}
                  >
                    Welcome to Brite. We’re so excited to have you here!
                  </div>

                  <div
                    className={css`
                      display: flex;
                      justify-content: center;
                      margin: 19px;
                      font-size: 16px;
                      color: #66737f;
                    `}
                  >
                    Choose or create a benefit package and automatically
                    generate your first Brite guide.
                  </div>
                </div>
              )}

            {!isLoading && currentFolder && isFolderEmpty() && (
              <div
                className={css`
                  margin: 50px auto;
                `}
              >
                <div
                  className={css`
                    margin-bottom: 40px;
                    display: flex;
                    justify-content: center;
                  `}
                >
                  <img
                    src={EmptyFolder}
                    alt="Empty folder"
                    width="220"
                    height="220"
                  />
                </div>
                <div
                  className={css`
                    font-size: 22px;
                    text-align: center;
                    font-weight: bold;
                    line-height: 29px;
                    color: #25282d;
                  `}
                >
                  This Folder is Empty
                </div>

                <div
                  className={css`
                    display: flex;
                    justify-content: center;
                    margin: 19px;
                    font-size: 16px;
                    color: #66737f;
                  `}
                >
                  Create or move a guide to this folder
                </div>
              </div>
            )}
          </Box>
          {isCarrier && (
            <div
              className={css`
                margin-top: 40px;
                padding-top: 24px;
                border-top: 1px solid #d1dae3;
              `}
            >
              <CarrierMarketingCourses
                delete={showDeleteGuide}
                duplicate={props.duplicateCourse}
                // Used to call handleTabValue, an Fn that doesnt exist -> goBackTab={this.handleTabValue(null, 0)}
                goBackTab={() => {}}
              />
            </div>
          )}
        </>
      )}
      {tab === PUBLISHED_TAB && (
        <>
          <Box flex="left">
            {isLoading && (
              <div
                className={css`
                  margin: 40px auto;
                `}
              >
                <CircularProgress />
              </div>
            )}

            {!isLoading && checkIsCoursePublishedMade && (
              <div className={courseListContainer}>
                {courses.map((course) => {
                  if (!course.IsPublished || course.ArchivedAt) {
                    return null;
                  }
                  return (
                    <div key={course.ID} className={courseCard}>
                      <div className="imageCard">
                        {!course.LogoURL ? (
                          <img
                            className={css`
                              width: 300px;
                              height: 160px;
                            `}
                            src={NoHeaderImage}
                            alt="No Header"
                          />
                        ) : (
                          <img
                            className={css`
                              max-height: 80px;
                              max-width: 250px;
                            `}
                            src={course.LogoURL}
                            alt="Guide Logo"
                          />
                        )}
                      </div>

                      <div className="hoverCard">
                        <div
                          className={css`
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            margin-top: 16px;
                          `}
                        >
                          <ACLWrapper
                            acls={["write:course"]}
                            resourceId={course.ID}
                          >
                            <Tooltip title="Delete" placement="top">
                              <IconButton
                                aria-label="Delete"
                                onClick={() => showDeleteGuide(course.ID)}
                              >
                                <Trash />
                              </IconButton>
                            </Tooltip>
                          </ACLWrapper>
                          <ACLWrapper
                            acls={["write:course"]}
                            resourceId={course.ID}
                          >
                            <Tooltip title="Duplicate" placement="top">
                              <IconButton
                                aria-label="Duplicate"
                                onClick={() => {
                                  handleDuplicate(course);
                                }}
                              >
                                <CopySimple />
                              </IconButton>
                            </Tooltip>
                          </ACLWrapper>
                        </div>
                        <div
                          className={css`
                            width: 180px;
                            margin: 0 auto;
                            padding-top: 26px;
                            margin-bottom: 16px;
                          `}
                        >
                          <ButtonUI
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={() => routeToBuilder(course.ID)}
                          >
                            {canWrite ? "Edit Guide" : "View Guide"}
                          </ButtonUI>
                        </div>
                        {canWrite && (
                          <div
                            className={css`
                              width: 180px;
                              margin: 0 auto;
                            `}
                          ></div>
                        )}
                      </div>
                      <div
                        className={css`
                          border-top: 1px solid #d1dae3;
                        `}
                      >
                        <div
                          className={css`
                            padding: 16px 24px;
                          `}
                        >
                          <div
                            className={css`
                              color: #36414c;
                              font-size: 22px;
                              line-height: 29px;
                            `}
                          >
                            {course.Name}
                          </div>

                          <div
                            className={css`
                              color: #66737f;
                              font-size: 16px;
                              line-height: 24px;
                              margin-top: 4px;
                            `}
                          >
                            {format(new Date(course.CreatedAt), "MMMM yyyy")}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}

            {!isLoading && !checkIsCoursePublishedMade && (
              <div
                className={css`
                  margin: 50px auto;
                `}
              >
                <div
                  className={css`
                    margin-bottom: 77px;
                    display: flex;
                    justify-content: center;
                  `}
                >
                  <img src={CoursesEmptyImage} alt="Guide" />
                </div>
                <div
                  className={css`
                    font-size: 22px;
                    text-align: center;
                    font-weight: bold;
                    line-height: 29px;
                    color: #25282d;
                  `}
                >
                  No published base guides
                </div>
              </div>
            )}
          </Box>
        </>
      )}
      {tab === ARCHIVED_TAB && (
        <>
          <Box flex="left">
            {isLoading && (
              <div
                className={css`
                  margin: 40px auto;
                `}
              >
                <CircularProgress />
              </div>
            )}

            {!isLoading && checkIsCourseArchivedMade && (
              <div className={courseListContainer}>
                {courses.map((course) => {
                  if (!course.ArchivedAt) {
                    return null;
                  }
                  return (
                    <div key={course.ID} className={courseCard}>
                      <div className="imageCard">
                        {!course.LogoURL ? (
                          <img
                            className={css`
                              width: 300px;
                              height: 160px;
                            `}
                            src={NoHeaderImage}
                            alt="No Header"
                          />
                        ) : (
                          <img
                            className={css`
                              max-height: 80px;
                              max-width: 250px;
                            `}
                            src={course.LogoURL}
                            alt="Guide Logo"
                          />
                        )}
                      </div>

                      <div className="hoverCard">
                        <div
                          className={css`
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            margin-top: 16px;
                          `}
                        >
                          <ACLWrapper
                            acls={["write:course"]}
                            resourceId={course.ID}
                          >
                            <Tooltip title="Unarchive" placement="top">
                              <IconButton
                                aria-label="Unarchive"
                                onClick={() => unArchiveCourse(course)}
                              >
                                <ArrowUUpLeft />
                              </IconButton>
                            </Tooltip>
                          </ACLWrapper>
                          <ACLWrapper
                            acls={["write:course"]}
                            resourceId={course.ID}
                          >
                            <Tooltip title="Delete" placement="top">
                              <IconButton
                                aria-label="Delete"
                                onClick={() => showDeleteGuide(course.ID)}
                              >
                                <Trash />
                              </IconButton>
                            </Tooltip>
                          </ACLWrapper>
                          {false && (
                            <Tooltip title="Analytics" placement="top">
                              <IconButton
                                aria-label="Analytics"
                                onClick={() => {}}
                              >
                                <ChartBar />
                              </IconButton>
                            </Tooltip>
                          )}
                          <Tooltip title="Download as PDF" placement="top">
                            <IconButton
                              aria-label="Download as pdf"
                              onClick={() => {
                                downloadAsPDF(course.ID);
                              }}
                            >
                              <DownloadSimple />
                            </IconButton>
                          </Tooltip>
                          <ACLWrapper
                            acls={["write:course"]}
                            resourceId={course.ID}
                          >
                            <Tooltip title="Duplicate" placement="top">
                              <IconButton
                                aria-label="Duplicate"
                                onClick={() => {
                                  handleDuplicate(course);
                                }}
                              >
                                <CopySimple />
                              </IconButton>
                            </Tooltip>
                          </ACLWrapper>

                          {!isManagingMultipleBusinesses && (
                            <Tooltip title="View" placement="top">
                              <IconButton
                                aria-label="View"
                                onClick={() => {
                                  if (course.VanityURL) {
                                    window.open(
                                      `https://britehr.app/${course.VanityURL}`
                                    );
                                  } else {
                                    window.open(
                                      `https://britehr.app/${course.ID}`
                                    );
                                  }
                                }}
                              >
                                <Eye />
                              </IconButton>
                            </Tooltip>
                          )}
                        </div>
                        <div
                          className={css`
                            width: 180px;
                            margin: 0 auto;
                            padding-top: 26px;
                            margin-bottom: 16px;
                          `}
                        >
                          <ButtonUI
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={() => routeToBuilder(course.ID)}
                          >
                            {canWrite ? "Edit Guide" : "View Guide"}
                          </ButtonUI>
                        </div>
                        {canWrite && (
                          <div
                            className={css`
                              width: 180px;
                              margin: 0 auto;
                            `}
                          >
                            {!isManagingMultipleBusinesses && (
                              <ButtonUI
                                variant="outlined"
                                color="primary"
                                disabled
                                fullWidth
                              >
                                Send Guide
                              </ButtonUI>
                            )}
                            {isManagingMultipleBusinesses && (
                              <ButtonUI
                                variant="outlined"
                                color="primary"
                                fullWidth
                                disabled
                                onClick={() => showAssignGuide(course)}
                              >
                                Assign Guide
                              </ButtonUI>
                            )}
                          </div>
                        )}
                      </div>
                      <div
                        className={css`
                          border-top: 1px solid #d1dae3;
                        `}
                      >
                        <div
                          className={css`
                            padding: 16px 24px;
                          `}
                        >
                          <div
                            className={css`
                              color: #36414c;
                              font-size: 22px;
                              line-height: 29px;
                            `}
                          >
                            {course.Name}
                          </div>

                          <div
                            className={css`
                              color: #66737f;
                              font-size: 16px;
                              line-height: 24px;
                              margin-top: 4px;
                            `}
                          >
                            {format(new Date(course.CreatedAt), "MMMM yyyy")}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}

            {!isLoading && !checkIsCourseArchivedMade && (
              <div
                className={css`
                  margin: 50px auto;
                `}
              >
                <div
                  className={css`
                    margin-bottom: 77px;
                    display: flex;
                    justify-content: center;
                  `}
                >
                  <img src={CoursesEmptyImage} alt="Guide" />
                </div>
                <div
                  className={css`
                    font-size: 22px;
                    text-align: center;
                    font-weight: bold;
                    line-height: 29px;
                    color: #25282d;
                  `}
                >
                  No archived guides
                </div>
              </div>
            )}
          </Box>
        </>
      )}
      {assignGuideIsOpen && (
        <AssignGuideModal
          display={assignGuideIsOpen}
          hideAssignGuide={hideAssignGuide}
          guide={assignGuide}
        />
      )}
      {newFolderModal.isOpen && (
        <NewFolderModal
          display={newFolderModal.isOpen}
          hideNewFolder={newFolderModal.hide}
          fetchFolders={fetchFolders}
        />
      )}
      {renameFolderIsOpen && (
        <RenameFolderModal
          display={renameFolderIsOpen}
          hideRenameFolder={hideRenameFolder}
          folder={renameFolder}
          fetchFolders={fetchFolders}
        />
      )}
      {duplicateGuideIsOpen && (
        <DuplicateGuideModal
          display={duplicateGuideIsOpen}
          hideDuplicateGuide={hideDuplicateGuide}
          guide={duplicateGuide}
        />
      )}
      {moveGuideIsOpen && (
        <MoveGuideModal
          display={moveGuideIsOpen}
          hideMoveGuide={hideMoveGuide}
          folders={folders}
          currentFolder={currentFolder}
          guide={moveGuide}
          fetchFolders={fetchFolders}
        />
      )}
      {removeFolderIsOpen && (
        <RemoveFolderModal
          display={removeFolderIsOpen}
          hideRemoveFolder={hideRemoveFolder}
          folder={removeFolder}
          fetchFolders={fetchFolders}
        />
      )}
      {deleteGuideIsOpen && (
        <DeleteGuideModal
          display={deleteGuideIsOpen}
          hideDeleteGuide={hideDeleteGuide}
          guideId={deleteGuideId}
        />
      )}
      {uploadImageIsOpen && (
        <SimpleReactFileUpload
          refresh={guidesResource?.query?.refetch}
          headerImageCourse={uploadImage}
          closeModal={() => {
            hideUploadImage();
          }}
        />
      )}
      {showDownloadPDFModal && (
        <Modal display onClose={() => setShowDownloadPDFModal(false)}>
          {loading && (
            <div
              className={css`
                margin: 40px auto;
              `}
            >
              <CircularProgress />
            </div>
          )}

          {!loading && !error && (
            <div
              className={css`
                padding: 24px;
              `}
            >
              <span
                className={css`
                  text-align: center;
                  font-size: 24px;
                  font-weight: bold;
                `}
              >
                Success!
              </span>
              <br />
              <br />
              We will email you when the PDF is ready. The PDF will also show up
              in Documents.
            </div>
          )}

          {error && (
            <div
              className={css`
                padding: 24px;
              `}
            >
              {error}
            </div>
          )}

          <div
            className={css`
              margin: 24px auto;
              display: flex;
              justify-content: center;
            `}
          >
            <Button onClick={() => setShowDownloadPDFModal(false)}>
              Close
            </Button>
          </div>
        </Modal>
      )}
    </Box>
  );
};
